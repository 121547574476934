<script setup lang="ts"></script>

<template>
  <section class="base-layout">
    <common-page-header></common-page-header>

    <slot></slot>
  </section>
</template>

<style lang="scss" scoped>
.base-layout {
  @apply padding-top-for-fixed-header;
}
</style>
